import student from '@/navigation/vertical/student'
import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'
import { mdiAccountOutline, mdiPlus, mdiEmailSend, mdiAccountCheck, mdiSchool, mdiSendCheck } from '@mdi/js'
import { inject, ref, watch } from 'vue'

export default function useRegularList() {
  const regularListTable = ref([])
  const tableColumns = [
    { text: 'Referencia', value: 'reference' },
    { text: 'Curso', value: 'courseName' },
    { text: 'Alumno', value: 'student' },
    { text: 'Empresa', value: 'company' },
    { text: 'Modalidad', value: 'modality' },
    { text: 'Fin del Curso', value: 'courseEnd' },
    { text: 'Estado', value: 'status' },
    {
      text: 'Acciones',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const instituteFilter = ref('')
  const statusFilter = ref('')
  const modalityFilter = ref('')
  const studentFilter = ref('')
  const companyFilter = ref('')
  const totalRegularListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const regularTotalLocal = ref([])
  const timer = ref('')
  const date = inject('date')
  const usersList = ref([])

  // fetch data
  const fetchRegulars = async () => {
    const response = await store.dispatch('app-regular/fetchRegulars', {
      q: searchQuery.value,
      institute: instituteFilter.value,
      status: statusFilter.value,
      modality: modalityFilter.value,
      company: companyFilter.value,
      student: studentFilter.value,
      options: options.value,
    })

    if (response.status == 200) {
      const { filteredData, total, regularTotal } = response.data

      regularListTable.value = filteredData
      totalRegularListTable.value = total
      regularTotalLocal.value = regularTotal
    }

    loading.value = false
  }

  store
    .dispatch('user/fetchUsers', {
      role: 'student',
    })
    .then(response => {
      const { filteredData } = response.data

      usersList.value = filteredData
    })
    .catch(error => {
      console.log(error)
    })

  // fetch data
  const deleteRegular = async id => {
    const response = await store.dispatch('app-regular/deleteRegular', { id })
    if (response.status == 200) {
      fetchRegulars()
      snackbarPlugin.showMessage({ content: 'Eliminado Correctamente', color: 'success', timeout: 10000 })
    } else {
      snackbarPlugin.showMessage({ content: response.data.message, color: 'error', timeout: 10000 })
    }
    loading.value = false
  }

  // send document
  const sendEnrollment = async id => {
    try {
      await store.dispatch('app-regular/sendEnrollment', { id })
      fetchRegulars()
      snackbarPlugin.showMessage({ content: 'Enviado Correctamente', color: 'success', timeout: 10000 })
    } catch (e) {
      snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
    }

    loading.value = false
  }

  watch([searchQuery, options], () => {
    if (timer.value) {
      clearTimeout(timer.value)
      timer.value = null
    }
    timer.value = setTimeout(() => {
      loading.value = true
      fetchRegulars()
    }, 800)
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveRegularTotalIcon = total => {
    if (total === 'alta') return { icon: mdiPlus, color: 'primary' }
    else if (total === 'inscripcion-enviada') return { icon: mdiEmailSend, color: 'primary' }
    else if (total === 'inscripto') return { icon: mdiAccountCheck, color: 'primary' }
    else if (total === 'cierre-enviado') return { icon: mdiSendCheck, color: 'primary' }
    else if (total === 'finalizado') return { icon: mdiSchool, color: 'primary' }
    else return { icon: mdiAccountOutline, color: 'primary' }
  }

  const resolveRegularModalityTitle = modality => {
    return store.state['app-regular'].modalityOptions.filter(el => el.value == modality)[0].title
  }

  const resolveRegularStatusesTitle = status => {
    if (status == 'total') return 'Total'
    return store.state['app-regular'].statuses.filter(el => el.value == status)[0].title
  }

  return {
    studentFilter,
    companyFilter,
    modalityFilter,
    sendEnrollment,
    instituteFilter,
    usersList,
    statusFilter,
    regularListTable,
    tableColumns,
    searchQuery,
    totalRegularListTable,
    loading,
    options,
    regularTotalLocal,
    timer,
    date,
    fetchRegulars,
    deleteRegular,
    resolveRegularTotalIcon,
    resolveRegularModalityTitle,
    resolveRegularStatusesTitle,
  }
}
